@use "sass:math";

@import "~@bfl/components/theming/constants";
@import "~@bfl/components/theming/variables";
@import "~@bfl/components/theming/utils";

.blue-icon {
  color: $primary-default;
}

.cursor-pointer {
  cursor: pointer;
}

.asset-radio {
  .mat-radio-label {
    background-color: transparent !important;
    .mat-radio-container {
      .mat-radio-inner-circle {
        background-color: $primary-default !important;
      }
      .mat-radio-outer-circle {
        border-color: $primary-default !important;
      }
    }
  }
}

a.go-back-link {
  display: flex;
  align-items: center;
}

bfe-icon.b2b-back-button {
  margin-right: math.div($bfc-default-spacing, 2);
  transform: rotate(180deg);
}